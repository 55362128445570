.mixes {
  width: 100%;

  &__title {
    text-align: center;
    margin-bottom: 4rem;
    color: var(--body-text-color);
  }
  &__mix {
    width: 70rem;
    max-width: 100%;
    margin: 0 auto;
  }
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--body-text-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-weight: 400;
  font-size: 4.25rem;
  margin-bottom: 1.25rem;
}

h2 {
  font-weight: 300;
  font-size: 3rem;
  width: 100%;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;

  &::after {
    content: '';
    background-color: var(--separator-color);
    margin-left: 2rem;
    height: 2px;
    flex: 1;
    margin-top: 0.75rem;
  }
}

a {
  color: hsla(0, 0%, 100%, 0.92);
}

.overlapped-underline {
  background-image: linear-gradient(
    110deg,
    var(--accent-color) 0%,
    var(--accent-color-darker-3) 100%
  );
  background-repeat: no-repeat;
  background-size: 100% 9px;
  background-position: 0 88%;
}

.separator {
  width: 100%;
  height: 2px;
  margin: 2.5rem 0;
  background-color: var(--separator-color);

  &--inverse {
    background-color: var(--separator-color-inverse);
  }
}

.social-link {
  padding: 0.5rem;

  &--twitter {
    background-color: #00aced;
    color: black;
  }

  &--linkedin {
    background-color: #0077b5;
    color: white;
  }

  &--github {
    background-color: #333;
    color: white;
  }
}

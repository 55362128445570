.content {
  padding: 7rem 3rem 15rem;
  max-width: 120rem;
  width: 100%;
  margin: 0 auto;

  @include respond(tab-land) {
    padding: 19rem 10rem 15rem;
  }

  &__section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;

    > * {
      width: 100%;
    }

    &:not(:last-child) {
      margin-bottom: 15rem;
    }
  }
}

.header {
  background-color: transparent;
  width: 100vw;
  padding: 4.5rem 8rem;
  display: flex;
  flex-direction: column;

  &__nav-list {
    align-self: flex-end;
    list-style: none;
  }

  &__nav-item {
    display: inline-block;
    font-size: var(--nav-font-size);

    a {
      color: var(--body-text-color);
      text-decoration: none;
      padding-bottom: 0.75rem;
      position: relative;

      &::before {
        content: '';
        opacity: 0;
        position: absolute;
        bottom: -0.125rem;
        left: -2.5%;
        width: 105%;
        height: 1px;
        background-color: var(--accent-color);
        transform: translateY(5px);
        transition: all 250ms ease-in;
      }

      &:hover::before {
        opacity: 1;
        transform: translateY(0);
      }
    }

    &:not(:last-child) {
      margin-right: 6rem;
    }
  }
}

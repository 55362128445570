.skillset {
  max-width: 100%;
  width: 55rem;
  padding-bottom: 7rem;
  padding-top: 5rem;

  &__title {
    text-align: center;
    color: var(--body-text-color-inverse);
    margin-bottom: 4rem;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: hsla(0, 0%, 98%, 1);
    padding: 4rem 0 1rem;
    box-shadow: 0 10px 20px 0 hsla(0, 0%, 0%, 0.1);
  }

  &__item {
    height: 10rem;
    margin-right: 1rem;
    margin-bottom: 3rem;

    &--react {
      margin-left: -2rem;
    }

    &--javascript,
    &--typescript {
      margin-right: 2rem;
    }
  }
}

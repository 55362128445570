.projects {
  h2 {
    margin-bottom: 4rem;
  }

  &__item {
    margin: 0 1rem;
    position: relative;

    &:not(:last-child) {
      margin-bottom: 10rem;
    }

    @include respond(tab-port) {
      margin: 0 5rem;
    }
  }

  &__item-text {
    text-align: center;
  }

  &__item-title {
    font-size: 3.5rem;
    display: block;
    text-decoration: none;
    color: var(--body-text-color);
    text-decoration: none;
  }

  &__item-overlay {
    background-color: hsla(235, 15%, 14%, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    position: absolute;
    padding: 0 0.5rem;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    transition: opacity 300ms ease-in-out;
    border-radius: 3px;

    @include respond(tab-port) {
      padding: 0 10rem;
    }
  }

  &__item-img {
    width: 100%;
    box-shadow: 0 2px 10px 0 hsla(0, 0, 0, 0.4);
    border-radius: 3px;
  }

  &__item-description {
    font-size: 2rem;
    font-weight: 300;
    color: var(--body-text-color);
    text-decoration: none;
  }
}

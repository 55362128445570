.sidebar {
  background-color: var(--sidebar-background);
  height: 100vh;
  width: 30rem;
  position: relative;

  &__nav-list {
    text-align: center;
    list-style: none;
    @include absoluteCenter();
  }

  &__nav-item {
    font-size: var(--nav-font-size);

    a {
      color: var(--body-text-color);
      text-decoration: none;

      &:hover {
        color: var(--body-text-color-darker-3);
        // TODO: put in after expanding line below hover
      }
    }

    &:not(:last-child) {
      margin-bottom: 3rem;
    }
  }
}

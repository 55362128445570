/* Use Vars for dark / light */
:root {
  --primary-background: hsl(235, 15%, 14%);
  --secondary-background: hsl(0, 0%, 90%);
  --sidebar-background: hsl(233, 15%, 14%);
  --body-text-color-lighter-1: hsla(0, 0%, 100%, 0.92);
  --body-text-color: hsla(0, 0%, 100%, 0.82);
  --body-text-color-darker-1: hsla(0, 0%, 100%, 0.72);
  --body-text-color-darker-2: hsla(0, 0%, 100%, 0.58);
  --body-text-color-darker-3: hsla(0, 0%, 100%, 0.44);
  --body-text-color-darker-4: hsla(0, 0%, 100%, 0.36);
  --body-text-color-darker-5: hsla(0, 0%, 100%, 0.25);
  --body-text-color-darker-6: hsla(0, 0%, 100%, 0.18);
  --body-text-color-inverse: hsla(0, 0%, 0%, 0.72);
  --separator-color-inverse: hsl(0, 0%, 27%);
  --separator-color: hsl(235, 15%, 19%);
  --accent-color: hsl(20, 91%, 78%);
  --accent-color-darker-3: hsl(20, 91%, 48%);
  --nav-font-size: 1.75rem;
}

.profile {
  text-align: center;
  max-width: 100%;
  width: 80rem;
  padding-top: 5rem;

  @include respond(tab-port) {
    padding-top: 0;
  }

  &__title {
    color: var(--body-text-color-lighter-1);
  }

  &__skill {
    width: 2rem;
    fill: var(--body-text-color-lighter-1);

    &:not(:last-child) {
      margin-right: 1.75rem;
    }

    &--javascript {
      fill: #f0db4f;
    }

    &--typescript {
      fill: #007acc;
    }

    &--angular {
      fill: #b52e31;
    }

    &--react {
      fill: #00d8ff;
    }

    &--redux {
      fill: #764abc;
    }

    &--sass {
      fill: #c69;
    }
  }

  &__description {
    font-family: 'Inconsolata';
    font-size: 2.1rem;
    line-height: 1.5;
    margin-bottom: 2.25rem;
  }

  &__description-line {
    &--1 {
      color: var(--body-text-color-darker-1);
    }

    &--2 {
      color: var(--body-text-color-darker-2);
    }

    &--3 {
      color: var(--body-text-color-darker-3);
    }

    &--4 {
      color: var(--body-text-color-darker-4);
    }

    &--5 {
      color: var(--body-text-color-darker-5);
    }

    &--6 {
      color: var(--body-text-color-darker-6);
    }
  }
}

* {
  padding: 0;
  margin: 0;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 43%;

  @include respond(tab-port) {
    // width < 900?
    font-size: 46%; //1 rem = 8px, 8/16 = 50%
  }

  @include respond(tab-land) {
    // width < 1200?
    font-size: 48%; //1 rem = 9px, 9/16 = 50%
  }

  @include respond(desktop) {
    font-size: 54%;
  }
}

body {
  background-color: var(--primary-background);
  min-height: 100vh;
  overflow-y: auto;
}

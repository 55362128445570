.contact {
  &__title {
    text-align: center;
    color: var(--body-text-color);
    margin-bottom: 4rem;
  }

  &__content {
    font-size: 2.2rem;
    color: var(--body-text-color);
    line-height: 1.5;
    text-align: center;
    font-weight: 300;
  }

  &__email {
    margin-bottom: 1rem;
  }
}
